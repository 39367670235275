import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import { Button, Col, Divider, Form, Input, Row, Select, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { useCallback, useMemo, useRef, useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Helmet } from "react-helmet";
import deleteIcon from "../../assets/delete-icon.png";
import editIcon from "../../assets/edit-icon.png";
import ivoryLogo from "../../assets/ivory-logo.png";
import { tab_access, tab_general, tab_roles, tab_users } from "../../utils/commonConsts";
import './Settings.css';

const Settings = () => {
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "calc(100% - 58px)", padding: "20px 30px" }),
    []
  );
  const [activeTab, setActiveTab] = useState(tab_general);
  const [profileForm] = Form.useForm();
  const [addUserForm] = Form.useForm();
  const [addRoleForm] = Form.useForm();
  const [fields, setFields] = useState([
    {
      name: ['cp_name'],
      value: 'Ivory ai',
    },
    {
      name: ['contact_person'],
      value: 'Pranav Prasad'
    },
    {
      name: ['email'],
      value: 'pranav.prasad@ivory.ai'
    },
    {
      name: ['phone_number'],
      value: '8988384899'
    }
  ]);
  const gridStyle = useMemo(() => ({ height: "560px", width: "100%" }), []);
  const accessGridStyle = useMemo(() => ({ height: "400px", width: "100%" }), []);
  const gridRef = useRef();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rowData, setRowData] = useState(
    [
      { name: "SUPPORT REPRESENTATIVE", role: "Administrator" },
      { name: "SALES REPRESENTATIVE", role: "Employee" },
    ]
  );

  const roleRowData = [
    {
      name: 'ADMINISTRATOR'
    },
    {
      name: 'EMPLOYEE'
    },
    {
      name: 'CONTRACTOR'
    }
  ]

  const accessRowData = [
    { modules: 'customers' },
    { modules: 'users' },
    { modules: 'subscriptions' }
  ]

  const columnDefs = [
    {
      field: "name",
      headerName: "NAME",
    },
    {
      field: "role",
      headerName: "ROLE",
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      cellRenderer: (params) => {
        const { id } = params.data;

        return (
          <div className="action-items">
            <img src={editIcon} alt="Edit" />
            <img src={deleteIcon} alt="Delete" />
          </div>
        )
      }
    }
  ]

  const roleColumnDefs = [
    {
      field: "name",
      headerName: "NAME",
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      cellRenderer: (params) => {
        const { id } = params.data;

        return (
          <div className="action-items">
            <img src={editIcon} alt="Edit" />
            <img src={deleteIcon} alt="Delete" />
          </div>
        )
      }
    }
  ]

  const accessColumnDefs = [
    {
      field: "modules",
      headerName: "MODULES",
    },
    {
      field: "view",
      headerName: 'VIEW',
      cellRenderer: (params) => {
        return (
          <div className="table-select">
            <Select
              className="select-dropdown"
              defaultValue="All"
              onChange={handleChange}
              options={[
                { value: 'All', label: 'All' },
                { value: 'My Own', label: 'My Own' },
                { value: 'None', label: 'None' },
              ]}
            />
          </div>
        )
      }
    },
    {
      field: "edit",
      headerName: 'EDIT',
      cellRenderer: (params) => {
        return (
          <div className="table-select">
            <Select
              className="select-dropdown"
              defaultValue="All"
              onChange={handleChange}
              options={[
                { value: 'All', label: 'All' },
                { value: 'My Own', label: 'My Own' },
                { value: 'None', label: 'None' },
              ]}
            />
          </div>
        )
      }
    },
    {
      field: 'delete',
      headerName: 'DELETE',
      cellRenderer: (params) => {
        return (
          <div className="table-select">
            <Select
              className="select-dropdown"
              defaultValue="All"
              onChange={handleChange}
              options={[
                { value: 'All', label: 'All' },
                { value: 'My Own', label: 'My Own' },
                { value: 'None', label: 'None' },
              ]}
            />
          </div>
        )
      }
    }
  ]

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      resizable: true, // Enable column resizing
    };
  }, []);

  const rowHeight = 52;
  const accessRowHeight = 74;

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const handleSaveButton = () => {

  }

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  return (
    <div style={containerStyle}>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <div className="settings">
        <div className="settings-header">
          <Tabs
            defaultActiveKey={tab_general}
            onChange={onTabChange}
            className="custom-tabs"
          >
            <TabPane tab="General" key={tab_general} />
            <TabPane tab="User Type" key={tab_users} />
            <TabPane tab="Roles" key={tab_roles} />
            <TabPane tab="Access Control" key={tab_access} />
          </Tabs>

          {activeTab !== tab_general &&
            <div className="d-flex">
              {activeTab === tab_users &&
                <div>
                  <Button className="add-clinic" onClick={handleShow}>
                    <PlusOutlined className="plus" />User
                  </Button>
                  <Offcanvas show={show} onHide={handleClose} placement="end">
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Add User</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Form
                        form={addUserForm}
                        className="user-form"
                      >
                        <Row className="user-form-field">
                          <span className="field-header-text">Profile Name</span>
                          <Form.Item
                            name="profile_name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the profile name!",
                              },
                            ]}
                          >
                            <Input className="user-form-input" />
                          </Form.Item>
                        </Row>
                        <Row className="user-form-field">
                          <span className="field-header-text">Role</span>
                          <Form.Item
                            name="role"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the profile name!",
                              },
                            ]}
                          >
                            <Select
                              className="user-form-select"
                              defaultValue="Select"
                              onChange={handleChange}
                              options={[
                                { value: 'Administrator', label: 'Administrator' },
                                { value: 'Employee', label: 'Employee' },
                                { value: 'Contractor', label: 'Contractor' },
                              ]}
                            />
                          </Form.Item>
                        </Row>
                      </Form>
                    </Offcanvas.Body>
                    <div className="button-container">
                      <Button className="cancel-button">
                        Cancel
                      </Button>
                      <Button className="add-button">
                        Add
                      </Button>
                    </div>
                  </Offcanvas>
                </div>
              }
              {activeTab === tab_roles &&
                <div>
                  <Button className="add-clinic" onClick={handleShow}>
                    <PlusOutlined className="plus" />Role
                  </Button>
                  <Offcanvas show={show} onHide={handleClose} placement="end">
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Add Role</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Form
                        form={addUserForm}
                        className="user-form"
                      >
                        <Row className="user-form-field">
                          <span className="field-header-text">Role Name</span>
                          <Form.Item
                            name="role_name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the role name!",
                              },
                            ]}
                          >
                            <Input className="user-form-input" />
                          </Form.Item>
                        </Row>
                      </Form>
                    </Offcanvas.Body>

                    <div className="button-container">
                      <Button className="cancel-button">
                        Cancel
                      </Button>
                      <Button className="add-button">
                        Add
                      </Button>
                    </div>

                  </Offcanvas>
                </div>
              }
              <Input
                className="search-box"
                type="text"
                id="filter-text-box"
                placeholder="Search"
                onInput={onFilterTextBoxChanged}
                suffix={<SearchOutlined />}
              />
            </div>}
        </div>
        <Divider plain></Divider>
        {activeTab === tab_general &&
          <div className="general-settings">
            <Col className="profile-pic-container">
              <Row className="pic-area">
                <img src={ivoryLogo} alt="Ivory Logo" />
              </Row>
              <Row className="edit-icon">
                <img src={editIcon} alt="Edit" />
              </Row>
            </Col>
            <Col>
              <Form
                form={profileForm}
                fields={fields}
                onFieldsChange={(newFields) => {
                  setFields(newFields);
                }}
                onFinish={handleSaveButton}
                className="profile-form"
              >
                <Row className="profile-form-field">
                  <span className="field-header-text">CPanel Name</span>
                  <Form.Item
                    name="cp_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the name!",
                      },
                    ]}
                  >
                    <Input className="profile-form-input" label="cp_name" />
                  </Form.Item>
                </Row>
                <Row className="profile-form-field">
                  <span className="field-header-text">Contact Person</span>
                  <Form.Item
                    name="contact_person"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the name of contact person!",
                      },
                    ]}
                  >
                    <Input className="profile-form-input" label="contact_person" />
                  </Form.Item>
                </Row>
                <Row className="profile-form-field">
                  <span className="field-header-text">Email</span>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email!",
                      },
                    ]}
                  >
                    <Input className="profile-form-input" label="email" />
                  </Form.Item>
                </Row>
                <Row className="profile-form-field">
                  <span className="field-header-text">Phone No</span>
                  <Form.Item
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the name!",
                      },
                    ]}
                  >
                    <Input className="profile-form-input" label="phone_number" />
                  </Form.Item>
                </Row>
              </Form>
            </Col>

          </div>
        }

        {activeTab === tab_users &&
          <div className="ag-theme-quartz"
            style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowHeight={rowHeight}
            />
          </div>
        }

        {activeTab === tab_roles &&
          <div className="ag-theme-quartz"
            style={gridStyle}>
            <AgGridReact
              className="role-data"
              ref={gridRef}
              rowData={roleRowData}
              columnDefs={roleColumnDefs}
              defaultColDef={defaultColDef}
              rowHeight={rowHeight}
            />
          </div>
        }

        {activeTab === tab_access &&
          <div className="access-settings">
            <div className="role-select">
              <label>Select Role</label>
              <Select
                className="select"
                defaultValue="Administrator"
                onChange={handleChange}
                options={[
                  { value: 'Administrator', label: 'Administrator' },
                  { value: 'Employee', label: 'Employee' },
                  { value: 'Contractor', label: 'Contractor' },
                ]}
              />
            </div>
            <div className="ag-theme-quartz"
              style={accessGridStyle}>
              <AgGridReact
                className="access-control-table"
                ref={gridRef}
                rowData={accessRowData}
                columnDefs={accessColumnDefs}
                defaultColDef={defaultColDef}
                rowHeight={accessRowHeight}
              />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Settings;
