import { CloseOutlined, MoreOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { Button, Col, Dropdown, Form, Input, Menu, message, Modal, Row } from "antd";
import { Divider, Tabs } from "antd/es";
import TabPane from "antd/es/tabs/TabPane";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import image1 from "../../assets/image1.jpg";
import usflag from "../../assets/usflag.png";
import API_BASE_URL from "../../env";
import { _status_ACTIVE, _status_DELETED, _status_INACTIVE, _status_PENDING, _status_SUBSCRIPTIONS, _status_TRAIL } from "../../utils/commonConsts";
import CellWithOverflowTooltip from "../CellWithOverflowTooltip/CellWithOverflowTooltip";
import "./Customers.css";
import { Helmet } from "react-helmet";

const ActionsRenderer = (props) => {
  const handleMenuClick = async (e) => {
    const Token = localStorage.getItem("Token");
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    if (e.target.innerHTML === "Approve") {
      const payload = {};
      await axios
        .put(
          `${API_BASE_URL}/api/v1/clinic/updateClinicStatus?id=${props.data.id}&status=${_status_ACTIVE}`,
          payload,
          config
        )
        .then((res) => {
          let tabActive = props.activeTab === _status_TRAIL ? _status_ACTIVE : props.activeTab;
          props.refreshData(tabActive);
        });
    } else if (e.target.innerHTML === "Reject") {
      const payload = {};
      await axios
        .put(
          `${API_BASE_URL}/api/v1/clinic/updateClinicStatus?id=${props.data.id}&status=${_status_INACTIVE}`,
          payload,
          config
        )
        .then((res) => {
          let tabActive = props.activeTab === _status_TRAIL ? _status_ACTIVE : props.activeTab;
          props.refreshData(tabActive);
        });
    }
  };

  return (
    <div className="options">
      <div className="approve" value="approve" onClick={handleMenuClick}>
        Approve
      </div>
      <div className="reject" value="reject" onClick={handleMenuClick}>
        Reject
      </div>
    </div>
  );
};


const Customers = () => {
  const Token = localStorage.getItem("Token");
  const gridRef = useRef();
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "calc(100% - 58px)", padding: "0 30px 20px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const [activeTab, setActiveTab] = useState(_status_PENDING);
  const [activeAddClinicModal, setActiveAddClinicModal] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const refreshData = useCallback((status) => {
    setRowData('');

    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/clinic/clinics?status=${status}`, config)
      .then((res) => {
        setRowData(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.data?.errors[0]);
      });
  });

  const handleDropdownMenuClick = async (id, key) => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    const payload = {};
    await axios
      .put(
        `${API_BASE_URL}/api/v1/clinic/updateClinicStatus?id=${id}&status=${key}`,
        payload,
        config
      )
      .then((res) => {
        let tabActive = activeTab === _status_TRAIL ? _status_ACTIVE : activeTab;
        refreshData(tabActive);
      });
  };

  const columnDefs = [
    {
      field: "clinic_name",
      headerName: "CLINIC NAME",
      cellRenderer: (params) => {
        <CellWithOverflowTooltip value={params.value} />;
        const { clinic_name } = params.data;
        return (
          <Row className="clinic-row-data-element">
            <Col>
              <img
                className="clinic-profile-pic"
                src={image1}
                alt="Profile Picture"
              />
            </Col>
            <Col className="clinic-name">{clinic_name}</Col>
          </Row>
        );
      },
      flex: 2,
    },
    {
      field: "contact_info",
      headerName: "POINT OF CONTACT",
      cellRenderer: (params) => {
        const { first_name, last_name, email } = params.data;
        return (
          <div className="row-data-element">
            <div className="full-name">{`${first_name} ${last_name}`}</div>
            <div className="email">{email}</div>
          </div>
        );
      },
      // flex: 1.5
    },
    {
      field: "phone_number",
      headerName: "PHONE NUMBER",
      cellRenderer: (params) => {
        const { phone_number } = params.data;
        return (
          <div className="row-data-element">
            <div className="phone">{phone_number}</div>
          </div>
        );
      },
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "CREATED DATE",
      cellRenderer: (params) => {
        const { created_at } = params.data;
        const created_date = moment(created_at).format("DD MMM YYYY, h:mm A");
        return (
          <div className="row-data-element">
            <div className="created-date">{created_date}</div>
          </div>
        );
      },
      flex: 1.5,
    },
    {
      field: "status",
      headerName: "ACCOUNT STATUS",
      cellRenderer: (params) => {
        const { status } = params.data;
        let statusClass = "";

        if (status === "PENDING") {
          statusClass = "status-pending"; // Pending tab
        } else {
          statusClass = "status-active";  // Trial and subscriptions tab
        }

        return (
          <div className="row-data-element">
            <div className={`status ${statusClass}`}>
              {status === "PENDING" ? "PENDING" : "ACTIVE"}
            </div>
          </div>
        );
      },
      flex: 1,
    },
    ...(activeTab === _status_PENDING
      ? [
        {
          field: "actions",
          headerName: "ACTIONS",
          cellRenderer: (params) => {
            const { id } = params.data;
            return (
              <div className="actions-list">
                <ActionsRenderer {...params} refreshData={refreshData} activeTab={activeTab} />
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="view">View Details</Menu.Item>
                      <Menu.Item
                        key="deactivate"
                        onClick={(key) =>
                          handleDropdownMenuClick(id, _status_DELETED)
                        }
                      >
                        Delete Clinic
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <MoreOutlined className="more" />
                </Dropdown>
              </div>
            );
          },
          flex: 1.5,
        }
      ] : []),
    ...(activeTab === _status_TRAIL
      ? [
        {
          field: "trial",
          headerName: "TRIAL ACCESS",
          cellRenderer: (params) => {
            const { plan_start_date, plan_end_date } = params.data;
            if (plan_start_date && plan_end_date) {
              const start = moment(plan_start_date);
              const end = moment(plan_end_date);
              const daysRemaining = end.diff(start, "days");

              if (daysRemaining > 0) {
                return (
                  <div className="row-data-element">
                    <div className="trial-days">{`${daysRemaining} days`}</div>
                  </div>
                );
              } else {
                return (
                  <div className="row-data-element">
                    <div className="expired">EXPIRED</div>
                  </div>
                );
              }
            } else {
              return (
                <div className="row-data-element">
                  <div className="expired"></div>
                </div>
              );
            }
          },
          flex: 1,
        },
        {
          field: "actions",
          headerName: "ACTIONS",
          cellRenderer: (params) => {
            <CellWithOverflowTooltip value={params.value} />;
            const { id } = params.data;
            return (
              <div className="row-data-element">
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="revoke"
                        onClick={(key) =>
                          handleDropdownMenuClick(id, _status_INACTIVE)
                        }
                      >
                        Revoke Approval
                      </Menu.Item>
                      <Menu.Item key="view">View Details</Menu.Item>
                      <Menu.Item
                        key="delete"
                        onClick={(key) =>
                          handleDropdownMenuClick(id, _status_DELETED)
                        }
                      >
                        Delete Clinic
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <MoreOutlined className="more" />
                </Dropdown>
              </div>
            );
          },
          flex: 0.75,
        }
      ]
      : []),
    ...(activeTab === _status_SUBSCRIPTIONS
      ? [
        {
          field: "subscriptions",
          headerName: "SUBSCRIPTIONS",
          cellRenderer: (params) => {
            const { plan } = params.data;
            return (
              <div className="row-data-element">
                <div className="plan">{plan}</div>
              </div>
            );
          },
          flex: 1,
        },
        {
          field: "actions",
          headerName: "ACTIONS",
          cellRenderer: (params) => {
            <CellWithOverflowTooltip value={params.value} />;
            const { id } = params.data;
            return (
              <div className="row-data-element">
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="revoke"
                        onClick={(key) =>
                          handleDropdownMenuClick(id, _status_INACTIVE)
                        }
                      >
                        Revoke Approval
                      </Menu.Item>
                      <Menu.Item
                        key="renew"
                        onClick={(key) =>
                          handleDropdownMenuClick(id, _status_ACTIVE)
                        }
                      >
                        Renew
                      </Menu.Item>
                      <Menu.Item key="view">View Details</Menu.Item>
                      <Menu.Item
                        key="delete"
                        onClick={(key) =>
                          handleDropdownMenuClick(id, _status_DELETED)
                        }
                      >
                        Delete Clinic
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <MoreOutlined className="more" />
                </Dropdown>
              </div>
            );
          },
          flex: 0.75,
        }
      ]
      : []),
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      resizable: true, // Enable column resizing
    };
  }, []);

  const rowHeight = 80;

  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
    };
  }, []);

  const onGridReady = useCallback((params) => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/v1/clinic/clinics?status=${activeTab}`, config)
      .then((res) => {
        setRowData(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.data?.errors[0]);
      });

    // Automatically size columns on grid ready
    params.api.sizeColumnsToFit();
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    // params.api.autoSizeAllColumns();
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onTabChange = (key) => {
    setActiveTab(key); // Set active tab
    if (key === _status_TRAIL) {
      refreshData(_status_ACTIVE)
    }
    else {
      refreshData(key);
    }  // Refresh data when tab changes
  };

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^\d{10}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number!");
  };

  const handleAdminRegister = async (values) => {
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      clinic_name: values.clinic_name,
      email: values.email,
      phone_number: values.phone_number,
      terms_accepted: true,
      is_register_by: "ivoryAdmin",
    };
    await axios
      .post(`${API_BASE_URL}/api/v1/clinic/clinic-sign-up`, payload)
      .then(async (res) => {
        message.success("Invite sent successfully");
        setActiveAddClinicModal(false);
      })
      .catch((err) => {
        // message.error(err.response.data.errors);
        message.error(err.response.data.data.errors[0]);
      });
  };

  return (
    <div style={containerStyle}>
      <Helmet>
        <title>Customer Dashboard</title>
      </Helmet>
      <div className="data-container">
        <div className="data-header">
          <Tabs
            defaultActiveKey={_status_PENDING}
            onChange={onTabChange}
            className="custom-tabs"
          >
            <TabPane tab="Sign up pending" key={_status_PENDING} />
            <TabPane tab="Trial" key={_status_TRAIL} />
            <TabPane tab="Subscriptions" key={_status_SUBSCRIPTIONS} />
          </Tabs>
          <div className="d-flex">
            <Button className="add-clinic" onClick={() => setActiveAddClinicModal(true)}>
              <PlusOutlined className="plus" />Add Clinic
            </Button>
            <Input
              className="search-box"
              type="text"
              id="filter-text-box"
              placeholder="Search"
              onInput={onFilterTextBoxChanged}
              suffix={<SearchOutlined />}
            />
          </div>
        </div>
        <Divider plain></Divider>
        <div style={gridStyle} className="ag-theme-quartz">
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            paginationAutoPageSize={true}
            pagination={true}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            tooltipShowDelay={0}
            rowSelection={rowSelection}
            rowHeight={rowHeight}
          />
        </div>
      </div>

      <Modal
        centered
        open={activeAddClinicModal}
        footer={false}
        closeIcon={<CloseOutlined onClick={() => setActiveAddClinicModal(false)} />}
      >
        <Form
          className="w-100 d-flex justify-center"
          onFinish={handleAdminRegister}
          form={form}
        >
          <Row className="signup-form">
            <Row className="w-100 d-flex jc-center">
              <span className="clinic-registration-text">
                Clinic Registration
              </span>
            </Row>
            <Row className="w-100">
              <span className="input-header-text">First Name</span>
              <Form.Item
                className="w-100"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "First name cannot be empty",
                  },
                  {
                    min: 2,
                    message: "Minimum character length should be 2",
                  },
                  {
                    max: 50,
                    message:
                      "First name must be no more than 50 characters long",
                  },
                  {
                    validator: (_, value) => {
                      const onlyLetters = /^[A-Za-z]+$/;
                      if (value && !onlyLetters.test(value)) {
                        return Promise.reject(
                          new Error(
                            "First Name cannot include special characters"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  bordered={false}
                  className="signup-input-fields"
                  onPaste={(e) => e.preventDefault()}
                />
              </Form.Item>
            </Row>
            <Row className="w-100">
              <span className="input-header-text">Last Name</span>
              <Form.Item
                className="w-100"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Last name cannot be empty",
                  },
                  {
                    min: 2,
                    message: "Minimum character length should be 2",
                  },
                  {
                    max: 50,
                    message:
                      "Last name must be no more than 50 characters long",
                  },
                  {
                    validator: (_, value) => {
                      const onlyLetters = /^[A-Za-z]+$/;
                      if (value && !onlyLetters.test(value)) {
                        return Promise.reject(
                          new Error(
                            "Last Name cannot include special characters"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  bordered={false}
                  className="signup-input-fields"
                  onPaste={(e) => e.preventDefault()}
                />
              </Form.Item>
            </Row>
            <Row className="w-100">
              <span className="input-header-text">Clinic Name</span>
              <Form.Item
                className="w-100"
                name="clinic_name"
                rules={[
                  {
                    required: true,
                    message: "Clinic name cannot be empty",
                  },
                  {
                    min: 2,
                    message: "Minimum character length should be 2 ",
                  },
                  {
                    max: 50,
                    message:
                      "Clinic name must be no more than 50 characters long",
                  },
                  {
                    validator: (_, value) => {
                      const onlyLettersAndSpaces = /^[A-Za-z\s]+$/;
                      if (value && !onlyLettersAndSpaces.test(value)) {
                        return Promise.reject(
                          new Error(
                            "Clinic Name cannot include special characters"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  bordered={false}
                  className="signup-input-fields"
                  onPaste={(e) => e.preventDefault()}
                />
              </Form.Item>
            </Row>
            <Row className="w-100">
              <span className="input-header-text">Work Email</span>
              <Form.Item
                className="w-100"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email" },
                  {
                    pattern:
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please enter a valid email address",
                  },
                  {
                    max: 320,
                    message:
                      "Email address must be no more than 320 characters long",
                  },
                ]}
              >
                <Input
                  bordered={false}
                  className="signup-input-fields"
                  onPaste={(e) => e.preventDefault()}
                />
              </Form.Item>
            </Row>
            <Row className="w-100 signup-phone-field-row">
              <span className="input-header-text">Phone Number</span>
              <Form.Item
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number",
                  },
                  {
                    pattern: /^[1-9]\d*$/,
                    message: "Phone number cannot start with a zero",
                  },
                  { validator: validatePhoneNumber },
                ]}
                className="w-100"
              >
                <Input
                  prefix={
                    <div className="country-code">
                      <img
                        alt=""
                        src={usflag}
                        style={{ width: 36, height: 36 }}
                      />
                      <span>+1</span>
                    </div>
                  }
                  onKeyPress={(e) => {
                    const charCode = e.which || e.keyCode;
                    if (!(charCode >= 48 && charCode <= 57)) {
                      e.preventDefault();
                    }
                  }}
                  bordered={false}
                  onPaste={(e) => e.preventDefault()}
                  onCopy={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                  className="signup-phone-field"
                />
              </Form.Item>
            </Row>
            <Row className="w-100">
              <Button
                className="register-button"
                htmlType="submit"
              >
                Register
              </Button>
            </Row>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default Customers;
