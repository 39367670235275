import { Button, Checkbox, Col, Form, Input, message, Row } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ivoryLogo from "../assets/ivorylogo.png";
import API_BASE_URL from "../env";
import {
  routes_dashboard,
  routes_forgot_password,
} from "../utils/RoutingConsts";
import {
  common_constants_email,
  common_constants_loginSuccess,
  common_constants_name,
  common_constants_password,
  common_constants_token,
} from "../utils/commonConsts";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [loginForm] = Form.useForm();
  const [rememberMe, setRememberMe] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const savedEmail = localStorage.getItem(common_constants_email);
    const savedPassword = localStorage.getItem(common_constants_password);

    if (savedEmail && savedPassword) {
      setRememberMe(true);

      loginForm.setFieldsValue({
        email: savedEmail,
        password: savedPassword,
      });
    }
  }, []);

  const handleLoginButton = async (values) => {
    const payload = {
      email: values.email,
      password: values.password,
    };
    setIsButtonDisabled(true);

    await axios
      .post(`${API_BASE_URL}/api/v1/clinic/clinic-login`, payload)
      .then((res) => {
        // openNotification('success', 'Login Successful');
        message.success(common_constants_loginSuccess);
        localStorage.setItem(
          common_constants_name,
          `${res.data.data.first_name} ${res.data.data.last_name}`
        );
        localStorage.setItem(common_constants_token, res.headers.authorization);
        navigate(routes_dashboard);

        if (rememberMe) {
          localStorage.setItem(common_constants_email, values.email);
          localStorage.setItem(common_constants_password, values.password);
        } else {
          localStorage.removeItem(common_constants_email);
          localStorage.removeItem(common_constants_password);
        }
        loginForm.resetFields();
      })
      .catch((err) => {
        message.error(err.response.data.data?.errors[0], 3);
        if (rememberMe) {
          localStorage.setItem(common_constants_email, values.email);
          localStorage.setItem(common_constants_password, values.password);
        } else {
          localStorage.removeItem(common_constants_email);
          localStorage.removeItem(common_constants_password);
        }
        // Re-enable the button after the message duration
        setTimeout(() => setIsButtonDisabled(false), 3000);
      });
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <Row className="login-page">
      <Helmet>
        <title>Control Panel Login</title>
      </Helmet>
      {/* <Col
        className="logo-desc-container"
        xs={0}
        sm={24}
        md={24}
        lg={13}
        xl={15}
        onClick={(e) => e.preventDefault()}
      >
        <CCarousel indicators>
          <CCarouselItem>
            <Row className="logo-container-div">
              <CImage className="ivory-login-bg" src={background} alt="" />
              <CCarouselCaption className="ivory-description">
                <span className="description-header">
                  AI-Powered Dental Assessments
                </span>
                <span className="description-content">
                  Instantly analyze dental X-rays using advanced AI technology
                  to identify potential issues, recommend treatments, and
                  provide comprehensive reports.
                </span>
              </CCarouselCaption>
            </Row>
          </CCarouselItem>
          <CCarouselItem>
            <Row className="logo-container-div">
              <CImage className="ivory-login-bg" src={background} alt="" />
              <CCarouselCaption className="ivory-description">
                <span className="description-header">
                  AI-Powered Dental Assessments
                </span>
                <span className="description-content">
                  Instantly analyze dental X-rays using advanced AI technology
                  to identify potential issues, recommend treatments, and
                  provide comprehensive reports.
                </span>
              </CCarouselCaption>
            </Row>
          </CCarouselItem>
          <CCarouselItem>
            <Row className="logo-container-div">
              <CImage className="ivory-login-bg" src={background} alt="" />
              <CCarouselCaption className="ivory-description">
                <span className="description-header">
                  AI-Powered Dental Assessments
                </span>
                <span className="description-content">
                  Instantly analyze dental X-rays using advanced AI technology
                  to identify potential issues, recommend treatments, and
                  provide comprehensive reports.
                </span>
              </CCarouselCaption>
            </Row>
          </CCarouselItem>
        </CCarousel>
      </Col> */}
      <Col className="form-container">
        <img className="ivory-logo-img" src={ivoryLogo} alt="" />
        <Row className="login-form">
          <span className="login-text">Login</span>
          <Form form={loginForm} onFinish={handleLoginButton}>
            <Row className="login-form-field">
              <span className="email-text">Email</span>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email!",
                  },
                ]}
              >
                <Input className="login-form-input" label="email" />
              </Form.Item>
              <span className="error-message"></span>
            </Row>
            <Row className="login-form-field">
              <span className="password-text">Password</span>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
              >
                <Input.Password className="login-form-input" label="password" />
              </Form.Item>
              <span className="error-message"></span>
            </Row>
            <Row>
              <Form.Item
                name="remember"
                valuePropName="checked"
                style={{ width: "100%" }}
              >
                <Row className="login-form-checkbox">
                  <Col className="remember-me-checkbox-col-tag">
                    <Checkbox
                      data-testid="remember-me-check-box"
                      className="remeber-me-checkbox"
                      type="checkbox"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    >
                      Remember me
                    </Checkbox>
                  </Col>
                  <Col>
                    <span
                      className="forgot-password-span-tag"
                      onClick={() => {
                        navigate(routes_forgot_password);
                      }}
                    >
                      Forgot Password?
                    </span>
                  </Col>
                </Row>
              </Form.Item>
            </Row>
            <Button
              className="login-button"
              htmlType="submit"
              disabled={isButtonDisabled}
            >
              Login
            </Button>
          </Form>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
