// Cpanel
const CPANEL_PROD_API_BASE_URL = `http://ec2-18-220-58-21.us-east-2.compute.amazonaws.com:8080`; // prod
const CPANEL_DEV_API_BASE_URL = `http://ec2-3-15-223-68.us-east-2.compute.amazonaws.com:8080`; // dev
const CPANEL_TEST_API_BASE_URL = `http://ec2-3-148-105-140.us-east-2.compute.amazonaws.com:8080`; // test / staging

// app
const APP_PROD_API_BASE_URL = `http://ec2-18-116-240-147.us-east-2.compute.amazonaws.com:8080`; // prod
const APP_DEV_API_BASE_URL = `http://ec2-52-14-179-203.us-east-2.compute.amazonaws.com:8080`; // dev
const APP_TEST_API_BASE_URL = `http://ec2-18-191-19-60.us-east-2.compute.amazonaws.com:8080`; // test / staging

const API_BASE_URL = CPANEL_TEST_API_BASE_URL;

export default API_BASE_URL;
//ec2-18-220-58-21.us-east-2.compute.amazonaws.com:8080/api/swagger-ui/index.html#/       :Control Panel Prod
//ec2-3-15-223-68.us-east-2.compute.amazonaws.com:8080/api/swagger-ui/index.html#/         : ControlPanel dev
//ec2-3-148-105-140.us-east-2.compute.amazonaws.com:8080/api/swagger-ui/index.html#/     :Control Panel Test

//ec2-18-116-240-147.us-east-2.compute.amazonaws.com:8080/api/swagger-ui/index.html#/    :PROD
//ec2-52-14-179-203.us-east-2.compute.amazonaws.com:8080/api/swagger-ui/index.html#/      :DEV
//ec2-18-191-19-60.us-east-2.compute.amazonaws.com:8080/api/swagger-ui/index.html#/        :TEST
